#root {
	#header {
		width: 100%;
		height: 82px;
		overflow-y: hidden;
		position: fixed;
		z-index: 20;
		top: 0;
		left: 0;
		box-shadow: 0 10px 14px 0 rgba(0, 0, 0, 0.2);
		#navigation {
			overflow: hidden;
			position: relative;
			background: $splight;
	
			@include mobile {
				overflow: visible;
			}
	
			.container {
				width: 90%;
				max-width: 100%;
				padding: 0;
				display: flex;
				align-items: center;
				height: 82px;
				flex-direction: row;
	
				#logo-container {
					min-width: 115px;
					flex: 1;

					.logo {
						display: block;
						margin-right: 0;
						max-width: 112px;
						max-height: 32px;
						min-width: 112px;
						min-height: 32px;
						background-image: url('../../../assets/icons/Eazle_Logo.png');
						background-size: contain;
						background-repeat: no-repeat;
						background-position: center;
						align-self: flex-start;
		
					}

					@media (max-width:1200px)  {
						flex: 0;
					}
				}
				
				.main-header-nav {
					flex: 3;

					@media (max-width:1200px)  {
						flex: 0;
						
						li {
							display: none;
						}

						li#menu-burger {
							display: block;
						}
					}

				}
	
				ul {
					list-style-type: none;
					margin: 0;
					padding: 0;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 100%;
					position: relative;
	
	
					li {
						padding: 0;
						min-width: 100px;
						button {
							text-align: center;
							margin: 0;
							cursor: pointer;
							font-size: 12px;
							padding: 8px;
							display: flex;
							flex-direction: column;
							align-items: center;
							font-weight: 700;
	
							img {
								max-height: 26px;
								margin-bottom: 7px;
							}
						}
					}
					
				}
	
				.navigation-right {
					flex: 1;
					display: flex;
					justify-content: end;
					align-items: center;
	
					> * {
						margin-left: 1.5em;
	
						&:first-child {
							margin-left: 0;
						}
					}
	
					a {
						img {
							margin-left: 0.4em;
						}
					}
	
					span {
						a {
							color: #1fa6a6;
						}
					}
					.pub-switch {
						display: flex;
						flex-direction: row;
						align-items: center;
						border-right: 1px solid $spdarkgray;
						padding-right: 20px;
						margin-right: 20px;

						span {
							text-transform: capitalize;
						}

						@media (max-width:500px) {
							display: none;
						}
					}
	
					.checkout {
						display: flex;
						padding-left: 1.5em;

						.checkout-van {
							display: flex;
							align-items: center;
						}
	
						img {
							height: 32px;
							width: 32px;
						}
					}
	
				}
			}
		}
	}
	
	.draw {
		position: fixed;
		left: -300px;
		top: 0;
		width: 300px;
		bottom: 0;
		z-index: 10;
		background: #00414B;
		box-shadow: 10px 0px 24px 0 rgba(0, 0, 0, 0.2);
		transition: 0.5s;
		padding: 40px 30px;
	
		&.active {
			left: 0;
		}
	
		.logo-container {
			.logo {
				display: block;
				margin-right: 0;
				max-width: 88px;
				max-height: 26px;
				min-width: 88px;
				min-height: 26px;
				background-image: url('../../../assets/icons/Eazle_Logo.png');
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
				margin-bottom: 30px;
			}
		}
	
		.pub-switch {
			display: flex;
			margin-bottom: 40px;
	
			span {
				font-weight: 600;
				margin-right: 14px;
				text-transform: capitalize;
			}
	
			img {
				width: 24px;
				height: 24px;
			}
		}
	
		.section-header {
			display: flex;
			align-items: center;
			font-size: 24px;
			line-height: 32px;
			font-weight: 600;
			margin-bottom: 10px;

			img {
				height: 24px;
				padding-right: 10px;
			}
		}
	
		ul {
			margin: 0 0 30px 0;
			padding: 0;
			list-style-type: none;
	
			li {
				margin: 0;
				padding: 0;
	
				button {
					text-align: left;
					display: block;
					font-size: 16px;
					line-height: 24px;
					padding: 5px 0;
				}
			}
		}
	}
}