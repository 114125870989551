#history {
	position: relative;

	#page-title {
		padding: 20px 0;
		text-align: center;
	}

	.content {
		z-index: 1;
	}

	.overlay {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: rgba(0,0,0,0.5);
	}

	.history-caption {
		@media (max-width:1200px)  {
			padding: 0 20px;
		}
	}

	
	.history-orders-container {
		padding: 0;
		border-radius: 14px 14px 0 0;

		@media (max-width:1200px)  {
			padding: 0 20px;
		}

		button.history-order-item {
			width: 100%;
			max-width: 1200px;
			display: block;
			border: 0;
			margin: 0 auto;
			padding: 14px 0;
			background: none;
			background-color: $splight;
			border-top: 1px solid $spdarkgray;

			&:hover {
				background-color: $spdark;
			}

			.container {
				display: flex;
				align-items: center;
			}
		}

		.history-order-item-bullet {
			width: 12px;
			height: 80px;
			background-color: $splightgrey;
			border-radius: 10px;
			margin-right: 20px;
		}

		.history-item-details {
			display: flex;
			align-items: center;

			.history-supplier-name {
				font-size: 16px;
				font-weight: 600;
				line-height: 24px;
			}

			.history-ref {
				padding: 0 30px;
				font-size: 12px;
				line-height: 16px;
				color: #fff;
			}
		}
		
		.history-item-arrow {
			margin-left: auto;
			width: 32px;
			height: 32px;
		}
	}

	.history-header {
		display: flex;

		@media (max-width:1000px)  {
			flex-direction: column;
		}
	}

	.history-search-container {
		display: flex;
		margin-bottom: 20px;
	}
	
}

#history-order {
	position: relative;

	.shadow {
		position: relative;
		width: 100%;
		height: 20px;
	}

	.search {
		z-index: 2;
	}

	#page-title {
		padding: 20px 0;
		text-align: center;
		position: relative;
		z-index: 1;
	}

	.content {
		z-index: 1;
	}

	.overlay {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: rgba(0,0,0,0.5);
	}

	.clear-search {
		display: none !important;
	}


	.history-orders-container {
		padding: 0;
		box-shadow: -10px 6px 8px 0px rgba(0,0,0,0.2);
		padding-left: 20px;

		.shadow-container {
			position: relative;
		}

		.history-order-product {
			width: 100%;
			border: 0;
			padding: 0;
			margin: 0;
			background: none;
			border-top: 1px solid $spdarkgray;

			&:first-child {
				border-top: none;
			}

			.history-product-on-display {
				display: flex;
				align-items: center;
				padding: 14px 0;

				&.cancelled {
					background-color: rgba(103,103,103, 0.5);
				}

				.history-line-title {
					flex: 1;
					text-align: left;
					margin-right: 10px;
					text-transform: capitalize;
				}

				.product-order-details-preview {
					margin-right: 20px;
					.history-product-drop-down-item {
						.product-order-details {
							display: flex;
							align-items: center;
							justify-content: center;

							img {
								align-self: center;
								width: 24px;
								margin-right: 10px;
							}

							p {
								margin: 0;
							}
						}
					}
				}
				.order-info-container {
					margin-left: auto;
					width: 30px;
					height: 30px;
					border-radius: 30px;
					line-height: 30px;
					text-align: center;
					font-size: 12px;
					margin-right: 20px;
					position: relative;
					
					.order-info-hover {
						display: none;
						position: absolute;
						color: #4f4f4f;
						background-color: white;
						padding: 4px 20px;
						text-transform: uppercase;
						min-width: 140px;
						border-radius: 5px;
						font-size: 18px;
						top: -50px;
						left: -100px;

						.arrow {
							width: 0px;
							height: 0px;
							border-left: 10px solid transparent;
							border-right: 10px solid transparent;
							border-top: 10px solid #fff;
							position: absolute;
							bottom: -10px;
							right: 16px;
						}
					}
					.order-info {
						margin-left: auto;
						width: 32px;
						height: 32px;
						border-radius: 32px;
						line-height: 32px;
						text-align: center;
						font-size: 12px;
						margin-right: 20px;
						font-weight: 800 ;

						&.fb {
							background: $green;

							&:hover {
								.order-info-hover--fb {
									display: block;
								}
							}
						}
						&.i {
							background: $spred;

							&:hover {
								.order-info-hover--i {
									display: block;
								}
							}
						}

					}



				}

				.history-order-item-bullet {
					width: 12px;
					height: 80px;
					background-color: $splightgrey;
					border-radius: 10px;
					margin-right: 20px;

					&.cancelled {
						background-color: #D80000;
					}
				}

				.history-item-arrow {
					width: 32px;
					height: 32px;
					margin-left: auto;
					transition: 0.2s;

					&.active {
						transform: rotate(90deg);
					}
				}
			}

			&:first-child {
				.history-product-on-display {
					border-radius: 10px 10px 0 0;
				}
			}

			.history-product-drop-down {
				display: flex;
				justify-content: center;
				padding: 14px;
				background-color: $spdark;

				.history-product-drop-down-item {
					padding: 14px;
					display: flex;
					flex-direction: column;
					align-items: center; 

					.product-order-details-header {
						width: 90px;
						font-size: 13px;
						margin-bottom: 14px;
						line-height: 15px;
						white-space: pre-line;
					}

					.product-order-details {
						display: flex;
						align-items: center;
						justify-content: center;

						img {
							align-self: center;
							width: 24px;
							margin-right: 14px;
						}

						p {
							margin: 0;
						}
					}
				}
			}
		}
	}
	.history-order-detail-container {
		margin-right: 20px;
		.history-back {
			margin: 0;
			padding: 0 0 20px 0;
			background: none;
			border: 0;
			width: 100%;
			text-align: left;
			display: flex;
			align-items: center;

			.history-item-arrow  {
				transform: rotate(180deg);
				height: 16px;
				margin-right: 7px;
			}
		}

		.history-order-detail {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			.order-details {
				width: 100%;
				display: flex;
				align-items: center;
				flex-direction: column;
				text-align: center;
				border: 1px solid $spdarkgray;
				border-radius: 20px;
				flex: 0 0 calc(33.333% - 10px);
				color: #fff;
				margin: 5px;
				box-sizing: border-box;
				min-height: 170px;

				h4 {
					color: white;
					font-size: 12px;
					font-weight: 600;
					margin-bottom: 0;
					display: block;
					width: 100%;
					border-bottom: 1px solid $spdarkgray;
					padding: 10px;
				}

				h5 {
					font-size: 16px;
					font-weight: normal;
					display: block;
					padding: 10px;
					margin-bottom: 0;
				}

				p {
					font-size: 12px;
					display: block;
					padding: 10px;
					margin-bottom: 0;
				}
			}
		}

		.history-order-detail-info {
			border: 0 !important;
			padding: 20px 10px;
		}
	}

	
}