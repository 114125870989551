#root #settings {
	@media (max-width:1200px)  {
		padding: 0 20px;
	}
	.flex-column {
		flex-direction: column;


		.log-button {
			display: flex;
			height: 40px;
			width: 320px;
			justify-content: space-between;
			position: absolute;
			right: 0;
			top: -88px;
			z-index: 10;

			.btn-container {
				display: flex;
				flex: 1;
				margin-left: 10px;
			}
		}
	}
	.container {
		position: relative;
	}

	.btn.has-icon {
		margin: 25px 0;
	}

	.settings-buttons {
		display: flex;
		flex-direction: column;
		margin-bottom: 40px;
		box-shadow: 0 10px 14px 0 rgba(0, 0, 0, 0.2);
	}

	.icon-button-container {
		margin: 0;
		border-bottom: 1px solid $spdarkgray;
		width: 100%;

		&:last-of-type {
			border: 0;
		}

		.btn {
			border-radius: 0 !important;
			background-color: transparent;
			border: none;
			padding: 10px 0;
			margin: 0;

			.icon-wrapper {
				padding: 0;
				background-color: $splightgrey;
				border-radius: 17px;
				border: 0;
				height: 80px;
				min-width: 80px;

				img {
					width: 32px;
					height: 32px;
				}
			}

			.icon-text {
				font-size: 24px;
				margin-left: 15px;
				line-height: 26px;
				padding: 0;
				background: none;

				p {
					text-align: left;
					font-size: 16px;
					font-weight: 600;
					line-height: 24px;
					
					&.icon-subtext {
						font-size: 14px;
						color: $spwhite;
						line-height: 20px;
						font-weight: 400;
					}
				}
			}


			.icon-arrow {
				width: auto;
			}
		}
	}
}

#cce-chat-widget {
	position: fixed;
	bottom: 2em;
	right: 1em;
	z-index: 10000;
}