#feedback {
	.container {
		max-width: 800px;

		h4 {
			margin-bottom: 1em;
		}

		#request-text,
		#feedback-text {
			min-height: 200px;
		}

		#stars-container {
			margin-bottom: 25px;
			text-align: center;

			img {
				height: 25px;
				margin: 0 10px;
			}
		}

		.btn {
			min-width: 160px;
			width: auto;
			margin: 0 auto;
			display: block;
		}
	}
}