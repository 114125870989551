.product-category {
	.favourite-back {
		margin-top: 30px;
		margin-bottom: 35px !important;
	}
}

#root #favourites {
	.btn--add-supplier, 
	.supplier-header-container .btn {
		padding: 1em 3em;
		border-radius: 100px;
		font-size: 0.9rem;
		width: auto;
	}

	.btn--add-supplier {
		position: absolute;
		right: 10px;
		top: -95px;
		z-index: 10;
	}

	.supplier-header {
		background: none;
	}

	.grouped-products {
		background: $spdark;
		box-shadow: inset 0 12px 10px 0px rgba(0, 0, 0, 0.2);
		padding: 2rem 0;
		margin: 0;

		& > div {
			width: 100%;
			max-width: 1200px;
			padding: 0;
			min-width: 300px;
			margin: 0 auto;
			position: relative;
		}

		.grouped-products-header.row {
			.col-md-3 {
				padding-right: 0;
				text-align: right;
				display: flex;
				justify-content: flex-end;
				align-items: center;
	
				span {
					margin-right: 23px;
				}
				
				.col-md-1.fill {
					height: 15px;
					padding: 0px !important;
					margin-right: 20px !important;
					display: flex;
					justify-content: end;
					align-items: center;
				}
			}
		}
	}

	#favourites-wrapper {
		#product-list-table {
			background: #003037;

			.columnheaders {
				box-shadow: inset 0 12px 10px 0 rgba(0,0,0,.2);
			}
		}
		.col-md-1.remove {
			padding: 0px !important;
			margin-right: 20px !important;
		}

		.supplier-header-container {
			background: white;
			padding: 1rem 0.5em;

			&.supplier-header-container--closed {
				box-shadow: inset 0 -12px 10px 0px rgba(0, 0, 0, 0.2);
			}

			.supplier-header {
				flex-direction: row;
				flex: 1;
				display: flex;
				border-top: none;

				.supplier-header-remove-wrapper {
					flex: 0 0 35px;
					margin-right: 0.5rem;
					justify-content: center;
					display: flex;

					.supplier-header-remove {
						flex: 1;
						justify-content: center;
						align-items: center;
						display: flex;
						cursor: pointer;

						img {
							width: 100%;
						}
					}
				}

				.supplier-header-name {
					flex: 1;
					flex-direction: column;
					align-items: flex-start;
					justify-content: center;
					display: flex;
					padding-left: 10px;

					h5 {
						font-size: 24px;
						color: $splight;
						font-weight: bold;
						text-align: center;
						margin-bottom: 0;
					}
				}

				.supplier-header-arrow {
					display: flex;
					flex: 0 0 35px;
					margin-left: 0.5rem;
					justify-content: center;

					.supplier-header-arrow-button {
						flex: 1;
						justify-content: center;
						align-items: center;
						display: flex;
						cursor: pointer;

						&.supplier-header-arrow-button--closed {
							transform: rotate(90deg);
						}

						&.supplier-header-arrow-button--open {
							transform: rotate(-90deg);
						}

						img {
							width: 100%;
						}
					}
				}
			}

			.supplier-header-buttons {
				margin: 1em 0 0.5em;
				justify-content: center;

				.btn {
					font-size: 0.9em;
					margin: 0 1em;
					width: auto;
				}
			}
		}

		.supplier-footer {
			margin-bottom: 30px;
		}
	}

	@media (max-width: #{$mobile-mini-width}) {
		> .container > .btn {
			display: block;
			margin: 0 auto;
		}

		.grouped-products {
			padding-left: 2rem;
			padding-right: 2rem;
		}
	}
}