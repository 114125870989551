#init {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .init-header {
        margin-top: 50px;
    }

    .loader,
    .updates,
    .user-inactive {
        flex: 3;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .user-inactive {
        max-width: 500px;
        margin: 0 auto;

        .login-logo {
            #logo {
                width: 200px;
                margin-bottom: 50px;
            }
        }

        button {
            margin-bottom: 20px;
        }
    }

    .loader {
        h4 {
            margin: 1em 0 0 0;
        }

        .login-logo {
            margin-top: 20px;
        }
    }

    .updates {
        flex: 1;

        .updates-container {
            padding: 20px;
            background-color: #026D7C;
            border-radius: 20px;
            min-width: 290px;
            text-align: center;
            min-height: 200px;
            margin-bottom: 30px;
        }
    }
}