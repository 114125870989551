.list-container {
	ul.list {
		margin: 0;
		padding: 0;
		list-style-type: none;

		li {
			&:nth-child(even) {
				background: $splight;
			}

			.list-contents {
				display: flex;
				padding: 14px 20px;

				.list-label {
					p {
						margin: 0;
					}
				}

				.list-edit {
					margin-left: auto;
					display: flex;
					justify-content: center;
					align-items: center;

					a {
						display: block;
						padding: 0 10px;

						img {
							width: 32px;
						}
					}
				}
			}

			ul.list--break {
				background: $spdark;
				border-bottom: 1px solid white;
				padding: 10px 20px;
				margin: 50px 0 0;

				li {
					h3 {
						font-size: 20px;
					}
				}
			}
		}

		&.list--users {
			li {
				display: flex;
				border-bottom: 1px solid $spdarkgray;
				height: 72px;

				&:last-of-type {
					border-bottom: 0;
				}

				.list-contents {
					padding: 0;
					align-items: center;
					flex: 1;

					.list-label {
						p {
							font-size: 16px;
							list-style: 24px;
						}
					}

					.list-edit {
						a {
							img {
								width: 24px;
								height: 24px;
							}
						}

						.icon--star {
							margin-left: 20px;
						}
					}
				}
			}
		}
	}
}