#reminders {
    @media (max-width:1200px)  {
		padding: 0 20px;
	}
    .reminders-title {
        display: flex;
        align-items: center;
        margin: 20px 0;
        position: relative;
        width: auto;
        h4.title {
            margin-bottom: 0;
            margin-right: 10px;
            width: auto;
        }

        .dropdown {
            width: auto;
        }

        .dropdown-menu {
            width: auto;
            .active {
                background-color: $splight;
            }
        }

        .new-reminder {
            position: absolute;
            right: 0;
            top: -110px;
            z-index: 10;
            width: auto;
        }
    }
    .reminders-type {
        display: flex;
        margin: 20px 0;

        .btn {
            flex: 1;
            border-radius: 0;
            border: 1px solid $spgreen;
            height: 44px;

            &:hover {
                background-color: $spgreen !important;
                color: $splight !important;
            }

            &:first-of-type {
                border-radius: 23px 0 0 23px;
            }

            &:last-of-type {
                border-radius: 0 23px 23px 0;
            }

            &.secondary {
                background-color: transparent;
                color: white;
            }
        }
    }

    .list-container {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}

.reminder {
    display: flex;
    color: white;
    padding: 10px 0;
    border-bottom: 1px solid $spdarkgray;

    .reminder-icon {
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 14px;
        background-color: $splightgrey;
        border-radius: 20px;


        &.reminder-icon-hein {
            background-color: initial;
            
            img {
                width: 50px;
                height: 50px;
            }
        }
        

        img {
            width: 32px;
            height: 32px;
        }

    }

    .arrow {
        width: 32px;
    }
}

.reminder-content {
    flex: 1;
    padding: 10px 10px 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h4 {
        font-size: 16px;
        line-height: 24px;
        color: white;
        font-weight: 600;
        margin-bottom: 0;
    }

    p {
        font-size: 14px;
        line-height: 20px;
        color: white;
        margin-bottom: 0;
    }
}

.reminder-summary {
    padding: 0 10px;

    .reminder {
        background-color: white;
        border-radius: 20px;
        margin-bottom: 20px;
        min-height: 64px;
        padding: 0;
        border-bottom: 0;

        .reminder-content {
            h4 {
                color: $splight;
            }
        
            p {
                color: $splight;
            }
        }

        .reminder-icon {
            width: 64px;
            height: auto;
            background-color: initial;

            img {
                width: 32px;
                height: 32px;
            }
        }
    }
}


.block--reminder {
    @media (max-width:1200px)  {
		padding: 0 20px;
	}
    .content {
        a {
            text-decoration: underline;
        }
    }
}