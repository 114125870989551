.popup {
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	z-index: 8;
	z-index: 999;

	&.popup--historyExport, &.popup--history, &.popup--reminder {
		#popup-box {
			overflow-y: visible;
		}
	}

	#popup-icon-container {
		text-align: center;
	}

	#alert-popup-icon {
		width: 50px;
		height: 50px;
		margin-bottom: 20px;
		align-self: center;
	}

	#alert-popup-content {
		font-size: 16;
        color: #ffffff;
        text-align: center;
		white-space: pre-wrap;

		a {
			color: $spgreen;

			&:hover {
				color: $spwhite;
				text-decoration: underline;
			}
		}
	}

	#alert-popup-btn-container {
		flex: 1;
		flex-direction: row;
	}

	#popup-filter {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.7);
		z-index: 9;
	}

	.input-error {
		border-color: red;
	}

	.label {
		margin: 10px 0;
		font-size: 18px;

		small {
			margin-bottom: 0;
		}
	}

	small {
		line-height: 1.4em;
		display: inline-block;
		font-size: 85%;
		margin-bottom: 20px;

		&.error {
			color: red;
			width: 100%;
			text-align: center;
		}
	}

	.popup-account-number {
		&::placeholder {
			color: #444;
			opacity: 1;
		}
		&:-ms-input-placeholder { /* Internet Explorer 10-11 */
			color: #444;
		}
		&::-ms-input-placeholder { /* Microsoft Edge */
			color: #444;
		}
	}

	#popup-box {
		background-color: $splight;
		border-radius: 22px;
		width: 100%;
		max-width: 400px;
		height: auto;
		position: relative;
		z-index: 99;
		margin: 0 auto;
		padding: 20px;
		max-height: 75vh;
		overflow-y: auto;

		#popup-close {
			position: absolute;
			top: 5px;
			z-index: 9;
			right: 10px;
			cursor: pointer;
	
			img {
				height: 24px;
				width: 24px;
				vertical-align: middle;
			}
	
			@include mobile {
				right: 5%;
				cursor: pointer;
			}
		}

		label {
			margin-bottom: 10px;
		}

		h5 {
			position: relative;
			margin-bottom: 2rem;
			font-size: 24px;
			line-height: 32px;

			&::after {
				content: '';
				display: block;
				border-bottom: 1px solid #696D77;
				margin-left: -20px;
				margin-right: -20px;
				padding-top: 1rem;
			}

			#popup-close {
				top: 0;
				right: 0;
			}
		}

		input:not(.plain) {
			border: 1px solid rgb(255, 255, 255);
			border-radius: 20px;
			padding: 15px;
		}

		input.plain {
			margin-bottom: 2rem;
		}

		#popup-quantity {
			padding: 10px;
			border-bottom: 1px solid $spwhite;
			margin: 0 auto;
			margin-bottom: 15px;
			text-align: center;
			width: 50%;
			color: $spwhite;
			font-size: 45px;
			height: auto;
			display: block;

			&::placeholder {
				color: gray;
			}
		}

		.filters {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			margin: 1em 0;

			.btn {
				width: auto;
				flex: 1;
				margin: 0 5px 8px 0;
				white-space: nowrap;
			}
		}

		.btn {
			display: block;

			line-height: 44px;
			border-radius: 22px;
			padding: 0;
			margin: 0;
		  	margin-top: 0.5rem;
			width: 100%;

			&#date-search-btn {
				width: 100%;
			}
		}

		.choice-button-container {
			.btn {
				margin: 20px 0 0 20px;

				&:first-child {
					margin-left: 0;
				}
			}
		}

		.mobile-form {
			display: flex;
		
			select {
				flex: 1;
				width: 160px;
				border-radius: 40px 0 0 40px;
				border-color: white;
			}
		
			input {
				border: 1px solid white;
				border-radius: 0 40px 40px 0;
				padding: 15px;
				flex: 5;
				border-left-width: 0;
			}
		}
	}

	&.popup--pubsuppliers {
		ul {
			margin-bottom: 1em;
			list-style: none;
			padding: 0;

			li {
				div {
					padding: 0.3em 0.5em;
					cursor: pointer;
				}
			}
		}
	}

	.permissions-form {
		.label {
		    font-size: 18px;
		    font-weight: bold;
		}
		.data {
		    margin-bottom: 14px;
		}

		.permission-types {
			list-style-type: none;
			padding: 0;
			border: 1px solid white;
			border-radius: 14px;
			overflow: hidden;
			margin: 14px 0 30px;

			li {
				padding: 10px;
				border-top: 1px solid black;

				&:first-child {
					border-top: 0;
				}
			}

			.user-permissions {
				&.user-permissions--active {
					background: $spgreen;
				}
			}
		}

		.confirmation {
			display: flex;
			align-self: center;
			margin-bottom: 30px;

			input {
				height: 30px;
				min-width: 0;
				max-width: 30px;
				margin: 0 10px 0 0;
			}
		}
	}
}