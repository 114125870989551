#user-form {
    margin-bottom: 35px;
    padding: 0 20px;

    #permissions-form {
        .label {
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
            margin-bottom: 10px;

            &.big {
                font-size: 24px;
                line-height: 32px;
                font-weight: 600;
            }
        }

        .data {
            margin-bottom: 15px;
        }

        input {
            width: 520px;
            max-width: 100%;
            border: 2px solid;
            border-radius: 23px;
            margin-bottom: 30px;
            padding-left: 20px;
        }

        #permissions {
            margin: 15px 0 30px;

            .permission-types {
                padding: 0;
                margin: 0;

                h5 {
                    font-size: 16px;
                    line-break: 24px;
                    padding: 12px 15px;
                    background: $spdark;
                    margin-bottom: 0;
                    font-weight: 600;
                    border-top: 1px solid $spdarkgray;
                }

                .permission-category {
                    padding: 16px 15px;
                    border-top: 1px solid $spdarkgray;

                    .user-permissions {
                        display: flex;
                        align-items: center;
                        list-style: none;

                        .permission-name {
                            flex: 1;
                            font-size: 16px;
                            line-height: 24px;
                        }

                    }
                }

            }
        }

    }
}

#root .checkbox-container {
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    width: 32px;
    height: 32px;
    
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked ~ .checkmark {
            background-image: url('../../../assets/icons/tick-green.svg');
            background-repeat: no-repeat;
            background-size: 24px;
            background-position: center; 
        }

        &:checked ~ .checkmark:after {
            display: block;
        }
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 32px;
        width: 32px;
        border: 1px solid $spgreen;
        border-radius: 8px;

        &:after {
            content: "";
            position: absolute;
            display: none;
        }

        &:after {
            left: 9px;
            top: 5px;
            width: 5px;
            height: 10px;
        }
    }
}

#root .user-form-footer {
    box-shadow: 8px -10px 12px 0 rgba(0, 0, 0, 0.2);
    padding-top: 30px;
    padding-bottom: 40px;
    
    #confirmation {
        display: flex;
        margin-bottom: 30px;
    
        p {
            flex: 1;
            margin-bottom: 0;
        }
    }
    button.btn {
        width: 366px;
        margin: 20px auto;
        display: block;
        max-width: 100%;
    }

    .checkbox-container {
        margin-right: 10px;
        margin-top: 4px;

        input {
            width: 32px;
            height: 32px;
            z-index: 10;
        }
    }
}