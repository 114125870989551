.block {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	border-radius: 20px;
	margin-bottom: 30px;
	border: 1px solid $spdarkgray;

	.header {
		display: flex;
		border-bottom: 1px solid $spdarkgray;
		width: 100%;
		height: 56px;
		align-items: center;

		h4 {
			font-size: 16px;
			font-weight: 600;
			line-height: 24px;
			padding-left: 20px;
			margin-bottom: 0;
			display: flex;
			align-items: center;
			flex: 1;
		}

		a {
			padding: 10px 20px;

			img {
				width: 24px;
				height: 24px;
			}
		}
	}

	.content {
		display: flex;
		flex-direction: column;
		width: 100%;
		text-align: left;
		padding: 10px 0;

		div {
			padding: 5px 20px;
			font-size: 16px;
			line-height: 24px;
			label {
				font-weight: 600;
				margin-bottom: 0;
			}

		}
		button {
			margin: 0 20px;
			flex: 1;
			width: auto !important;
		}
	}



	&.block--next-delivery {
		position: relative;
		flex-direction: column;
		width: 366px;
		margin: 70px auto 20px;
		text-align: left;
		padding: 0;
		background-color: white;
		height: auto;

		.genie {
			position: absolute;
			width: 44px;
			right: 18px;
			top: 6px;
		}

		.block--row {
			display: flex;
			justify-content: flex-start;
			width: 100%;
			flex-direction: column;
			padding: 10px 0;

			&.block--top {
				border-bottom: 1px solid $spgray;
				display: flex;
			}
			.block--column {
				color: $splight;
				font-size: 16px;
				line-height: 24px;
				flex: 1.3;
				padding: 0px 20px;

				&.block--header {
					flex: 1;
					font-weight: 600;
				}
			}
		}
	}

	&.block--update {
		align-items: flex-start;
		padding: 40px 20px 20px;

		.genie {
			top: -22px;
		}
		
		h4, p {
			color: $splight;
		}

		h4 {
			font-size: 24px;
			line-height: 32px;
			font-weight: 600;
		}

		p {
			font-size: 16px;
			line-height: 24px;
		}
	}

	&.block--recent-order {
		h4 {
			&::before {
				background-image: url('../../../assets/icons/SmartPub-product-pos.svg');
			}
		}
	}

	&.block--cleaning-info {
		.block--row {
			display: flex;
			justify-content: flex-start;
			width: 100%;

			&.block--top {
				display: flex;
				align-items: flex-end;
			}
			.block--column {
				color: white;
				font-size: 16px;
				line-height: 24px;
				flex: 1.3;
				padding: 14px 0px;

				&.block--header {
					flex: 1;
					font-weight: 600;
				}
			}
		}
	}

	&.block--financial-info {
		h4 {
			&::before {
				background-image: url('../../../assets/icons/SmartPub-product-pos.svg');
			}
		}
	}

	&.block--reminder {
		border: 0;
		flex: 1;

		.header {
			border: 0;

			h4 {
				font-size: 24px;
				line-height: 32px;
				padding-left: 0;
			}
		}

		.content {
			div {
				padding: 10px 0;
			}
		}
	}

	&.block--integrated, &.block--non-integrated {
		flex: 1;
		.header {
			flex-direction: column;
			padding: 14px 0;
			height: auto;

			h5 {
				font-size: 16px;
				line-height: 24px;
				margin-bottom: 10px;
			}

			h6 {
				font-size: 12px;
				line-height: 16px;
				margin-bottom: 0;
			}
		}

		.content {
			justify-content: center;
			align-items: center;
			padding: 20px;
			font-size: 16px;
			line-height: 24px;
			text-align: center;

			img {
				width: 24px;
				margin-bottom: 10px;
			}
		}
	}
	
	&.block--pub-select {
		.content {
			padding: 0;
			.pub-list {
				padding: 0;
				height: 300px;
				overflow-y: auto;
				.pub-name {
					display: block;
					padding: 14px 20px;

					p {
						margin: 0;
						color: white;
						text-transform: capitalize;
						font-size: 16px;
						line-height: 24px;
					}

					&.pub-name--active {
						background-color: $spgreen;
						
						p {
							color: $splight;
						}

						&:last-of-type {
							border-radius: 0 0 20px 20px;
						}
					}
				}
			}
		}
	}
}