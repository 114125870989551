// App-generic
html {
	height: 100vh;
}

body {
	font-family: 'Inter', sans-serif !important;
	min-height: 100%;
	background-color: $splight !important;

	#root {
		min-height: 100vh;

		#page-wrapper {
			min-height: calc(100vh - 82px);
			display: flex;
			flex-direction: column;

			#history-order {
				display: flex;
				flex-direction: column;
				flex: 1;

				.container.content {
					flex: 1;
				}
			}
		}
	}
	
}

.page-wrapper {
	position: relative;
	min-height: calc(100vh - 82px);

	@include mobile {
		min-height: calc(100vh - 82px);
	}
}

.icon.icon--tick {
	height: 60px;
	width: 60px;
	display: inline-block;
	background-size: contain;
	background-image: url('../../assets/icons/tick.png');
	background-image: url('../../assets/icons/tick.svg');
	background-repeat: no-repeat;
	background-position: center;
}

p.highlight {
	color: $spgreen;
}

label:focus, input[type="date"]:focus {
	outline: none !important;
	box-shadow: none !important;
}

.product-list-empty {
	text-align: center;
	color: white;
	margin: 20px;
}

.sort-bar {
	margin-top: 30px;
	display: flex;
	background-color: $splight;
	padding-left: 10px;
	padding-right: 10px;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	z-index: 8;
	height: 76px;
	box-shadow: 10px 0 5px 5px rgba(0, 0, 0, 0.2);

	#sort-bar-container {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	button {
		margin-bottom: 0 !important;
		padding: 5px 20px;
		width: auto !important;

		&.primary {
			margin-right: 20px;
		}

		&.blank {
			padding: 5px 10px;
		}

		img {
			width: 32px;
			height: 32px;
		}
	}
}

.margin-bottom {
	margin-bottom: 30px;
}

.filter-sort {
	display: flex;
	align-items: center;
	font-size: 16px;
	font-weight: 600;
	position: absolute;
	right: 10px;
	top: -82px;
	z-index: 10;

	&.filter-sort--order {
		top: -196px;
	}

	img {
		width: 46px;
		padding-left: 10px;
	}
}

.display-flex {
	display: flex;
}

.rotate-180 {
	transform: rotate(180deg);
}

.product-category {
	.supplier-header {
		margin-top: 30px;
	}
}

.supplier-header {
	border-top: 1px solid #696D77;
	background: white;
	max-width: 1200px;
	margin: 0 auto;
	width: 100%;

	.container {
		display: flex;
		align-items: center;

		.supplier-name {
			flex: 1;
			font-size: 24px;
			line-height: 32px;
			font-weight: 600;
			padding: 16px 20px 16px 20px;
			color: $splight;
		}

		button {
			margin-right: 20px;
		}

		.change-button {
			padding-right: 20px;
		}
	}
}

.grouped-products {
	margin-bottom: 15px;
}

#buttons {
	display: flex;

	button {
		margin: 5px;
	}
}

#product-search-bar {
	margin-top: 15px;
}

#product-search-trigger {
	position: absolute;
	right: 10px;
	top: -88px;
	display: flex;
	background-color: $spgreen;
	width: 60px;
	height: 42px;
	justify-content: center;
	align-items: center;
	border-radius: 20px;
	z-index: 10;

	img {
		height: 24px;
		width: 24px;
	}
}

#favourites-wrapper {
	.product-list-item {
		padding: 10px 0;
	}
}

.sortable {
	a.sort-arrows {
		height: 24px;
		width: 24px;

		img {
			height: 100% !important;
			width: 100% !important;
		}
	}

	a.editable, a.sort-arrows {
		display: inline-block !important;
	}
}

#product-search-container, #product-search-container > form {
	display: flex;
	width: 100%;
	margin-bottom: 20px;

	#product-search-bar {
		width: 90%;
		border-radius: 40px;
		display: flex;
		border: 1px solid #fff;
		text-indent: 15px;
		margin: 0 20px 0 0;
	}

	button {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		flex-grow: 1;
		width: 10%;
		max-width: 200px;
		min-width: 100px;
	}
}

#product-search, .product-category {
	#product-list-table {
		#product-list {
			.product-list-item {
				>.row {
					padding: 10px 0;
				}
			}
		}
	}
}

.white-box {
	border-radius: 11px;
	background-color: #ffffff;
	padding-top: 15px;
	padding-bottom: 15px;
	padding-right: inherit;
	padding-left: inherit;

	.row {
		margin-bottom: 15px;

		&:last-child {
			margin-bottom: 0;
		}

		p {
			margin: 0;
		}

		.white-box-title {
			font-size: 16px;
			font-weight: bold;
		}

		.white-box-content {
			font-size: 16px;
			color: #000000;
		}
	}
}

body {
	margin: 0;
	padding: 0;
	color: $spwhite;
	font-size: 14px;
	min-height: 100%;

	* {
		color: $spwhite;

		&:active, &:focus {
			outline: none !important;
		}

		&:focus {
			box-shadow: none !important;
		}
	}

	a {
		cursor: pointer;
	}

	img {
		max-width: 100%;
	}

	hr {
		border-color: white;
		opacity: 0.3;
		margin: 1em 2em 2em;
	}

	.text-black {
		color: $spblack;
	}

	.content-background-wrapper {
		@include background-overlay;
		
		&.center {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.banner {
		width: 100%;
		margin-bottom: 20px;
		min-height: 190px;
		max-height: 190px;
		@include aspect-ratio(16, 3);
		box-shadow: 0 10px 14px 0 rgba(0, 0, 0, 0.2);
		overflow: hidden;
		.ratio-container {
			background-image: url('../../assets/backgrounds/banner-image.jpg');
			@include background-size(cover);
			display: flex;

			&:before {
				content: "";
				@include background-overlay;
			}

			#page-title {
				margin: auto auto 30px auto;
				width: 1200px;
				position: relative;
				z-index: 8;
				font-size: 32px;
				line-height: 40px;
				font-weight: 600;

				@media (max-width:1200px)  {
					padding-left: 20px;
				}
			}
        }
	}

	.page-button-container {
		margin: 20px auto;
		width: 50%;
	}

	#search-container {
		position: relative;
		margin-bottom: 16px;

		#search-icon {
			position: absolute;
			top: 0;
			left: 20px;
			height: 20px;
			width: 20px;
			bottom: 0;
			margin: auto 0;
		}

		#search {
			border-radius: 40px;
			border: 2px solid $spwhite;
			padding: 10px 0;
			text-indent: 50px;
			height: auto;
			margin-bottom: 0;

			&::placeholder {
				color: gray;
			}
		}
	}

	.table-header {
		height: 60px;
		padding: 0 20px;
		line-height: 25px;
		margin: 10px 0;
		display: flex;
		align-items: center;
		border-radius: 0;
		margin-bottom: 0px;
		max-width: 1200px;
		margin: 0 auto;

		&#sort-bar {
			position: fixed;
			bottom: 80px;
			left: 0;
			right: 0;
			padding: 0;

			.container {
				padding: 0;
				> div {
					padding: 10px 0;

					&.col-md-1 {
						text-align: center;
					}
				}
			}
		}

		.col-md-8 {
			p {
				padding-left: 0px;
			}
		}

		.col-md-0 {
			width: 82px;

			&.pr-0 {
				width: 22px;
			}
		}

		p {
			font-size: 16px;
			line-height: 20px;
			font-weight: 600;
		}

		&.order-header {
			line-height: 21px;
			margin: 0 auto;
			padding: 0;
		}

		&.blank {
			background-color: transparent;
		}

		p {
			margin: 0;
		}

		>div {
			display: flex;
			align-items: center;

			&:first-of-type {
				padding-left: 0;
			}

			&:last-of-type {
				padding-right: 0;
			}
		}

	}

	.text-center, .text-right, .text-left {
		display: block;
		width: 100%;
	}

	#page-title {
		margin: 25px 0;
		.page-sub-title {
			display: block;
			color: white;
			font-size: 16px;
		}
	}

	.go-back {
		display: flex;
		align-items: center;
		margin-bottom: 20px;

		img {
			width: 32px;
			height: 32px;
			transform: rotate(180deg);
		}
	}

	#root {
		#page-bg {
			position: fixed;
			height: 100%;
			width: 100%;
			top: 0;
			left: 0;
			z-index: -1;
			background-color: $splight;
		}

		#page-wrapper {
			&.has-footer {
				margin-bottom: 100px;
			}

			&.has-header {
				margin-top: 82px;
			}
		}
	}

	.row {
		margin: 0;
	}

	.clickable {
		cursor: pointer;
	}

	.container {
		width: 100%;
		max-width: 1200px;
		padding: 0;
		min-width: 300px;
		margin: 0 auto;
		position: relative;

		&.container--small {
			max-width: 784px;

			.col-md-6 {
				padding-left: 10px;
				padding-right: 10px;
			}
		}
	}

	a {
		color: $spwhite;
		text-decoration: none;

		&:hover {
			text-decoration: none;
			color: $spwhite;
		}

		&:active, &:focus {
			outline: none !important;
		}

		&.blue {
			color: $spgreen;

			&:hover {
				text-decoration: none;
				color: $spgreen;
			}
		}

		&.light {
			color: $spwhite;

			&:hover {
				text-decoration: none;
				color: $spwhite;
			}
		}

		&.dark {
			color: $spblack;

			&:hover {
				text-decoration: none;
				color: $spblack;
			}
		}
	}

	@import '_inputs';

	.jumbotron {
		background-color: $spwhite;
		border-color: $spwhite;
		border-width: 1px;
		border-style: solid;
		padding: 10px 15px;
		color: $spblack;
		border-radius: 10px;

		* {
			color: $spblack;
		}

		&.light {
			background-color: $spgray;
			border-color: $spwhite;

			input {
				border-color: $spblack;
			}
		}

		&.dark {
			background-color: transparent;
			border-color: transparent;

			* {
				color: $spwhite;
			}
		}

		&.blue {
			background-color: $spgreen;
			border-color: $spgreen;
		}

		&.blue.dark {
			background-color: transparent;
			border-color: $spgreen;
		}

		.jumbotron-title, .jumbotron-icon, .jumbotron-content, .jumbotron-content * {
			padding: 0;
		}

		.jumbotron-title {
			margin-bottom: 10px;
		}
	}
}