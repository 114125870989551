#order {
	.container {

		>.row >.container {
			padding-top: 0;
			padding-bottom: 0;
		}
	}
}

#history-loading {
	text-align: center;
	
	> div {
		width: 100%;
	}
}

#order-confirmation {
	margin-bottom: 100px;
}

#order-new {
	.order-header {
		.flex-fill {
			width: auto;
		}
	}
	.container {
		max-width: 1200px;

		#reset-order {
			position: absolute;
			z-index: 8;
			top: 0;
			right: 10px;
			width: 100px;
		}


		#order-view {
			position: absolute;
			z-index: 8;
			top: 0;
			left: 10px;
			width: 150px;
		}

		.table-header {
			margin: 30px;
		}

		#product-list {

			>.row>div {
				padding: 0;

				&:last-of-type {
					padding-left: 10px;
				}

				&:first-of-type {
					padding-right: 10px;
				}
			}
			.product-list-item {
				margin: 0;
				padding: 6px 0;

				.row {
					.product-list-item-left {
						.product-list-item-name {
							display: flex;
							flex-direction: column;
							align-items: baseline;

							p {
								margin: 0;
							}
						}
					}
				}
				
				

				.product-list-item-value {
					text-align: center;
				}

				.col-md-1 {
					text-align: right;
					padding-right: 0;
				}

				.row {
					.product-list-item-left {
						.product-list-item-name {
							height: auto;
						}
					}
				}
			}
		}
	}
}

.mobile-form {
	display: flex;

	select {
		flex: 1;
		width: 160px;
		border-radius: 40px 0 0 40px;
		border-color: white;
	}

	input {
		border: 1px solid white;
		border-radius: 0 40px 40px 0;
		padding: 15px;
		flex: 5;
		border-left-width: 0;
	}
}

#order-checkout {
	.container {

		#product-list {
			padding: 0 20px;
			margin-top: 10px;
		}
	}
}

#all-products {

	.btn.has-icon {
		margin: 25px 0 0;

		&.medium {
			margin-top: 0;
		}
	}

	#product-list-table {
		#product-list {
			.product-list-item {
				margin-bottom: 5px;
				padding: 3px 10px;
			}
		}
	}

	#category-list-table {
		#category-list {
			padding: 0;

			h5 {
				margin-bottom: 15px;
			}
		}
	}

	&.product-category {
		#product-list-table {
			#product-list {
				.product-list-item {
					.product-list-item-name {
						line-height: 30px;
					}
				}
			}
		}
	}
}

#root .product-category {
	.category-header {
		display: flex;
		align-items: center;

		#page-title {
			flex: 1;
		}

		#search-container {
			margin-bottom: 0;
			width: 320px;
			max-width: 100%;

			#search {
				background-image: url('../../../assets/icons/search-icon-green.svg');
				background-repeat: no-repeat;
				background-size: 32px;
				background-position: 10px;
			}
		}
	}
	#category-list-table {
		#category-list {
			padding: 0;
			margin-top: 20px;
			.icon-button-container {
				margin: 0;
				border-top: 1px solid $spdarkgray;
				width: 100%;
		
				.btn {
					border-radius: 0 !important;
					background-color: transparent;
					border: none;
					padding: 10px 0;
					margin: 0;
		
					.icon-wrapper {
						padding: 0;
						background-color: $splightgrey;
						border-radius: 17px;
						border: 0;
						height: 80px;
						min-width: 80px;
		
						img {
							width: 32px;
							height: 32px;
						}
					}
		
					.icon-text {
						font-size: 24px;
						margin-left: 15px;
						line-height: 26px;
						padding: 0;
						background: none;
		
						p {
							text-align: left;
							font-size: 16px;
							font-weight: 600;
							line-height: 24px;
							
							&.icon-subtext {
								font-size: 14px;
								color: $spwhite;
								line-height: 20px;
								font-weight: 400;
							}
						}
					}
		
		
					.icon-arrow {
						width: auto;
					}
				}
			}
		}
	}

	&.product-single-category, &.product-category {
		#product-list-table {
			#product-list {
				.product-list-item {
					height: 100px;
					display: flex;

					.row {
						flex: 1;

						.col-md-12 {
							display: flex;
							.row:last-of-type {
								flex: 0;
							}
						}
					}

					.product-list-item-left {
						display: flex;

						.product-list-item-name-container {
							flex: 1;
							height: 100%;

							.product-list-item-name {
								flex: 1;

								a {
									width: 100%;
									display: flex;
									align-items: center;
								}
							}
						}
					}

					.product-list-item-right {

						.pin-add {
							display: flex;
							.add-button {
								width: 42px;
								height: 60px;
								background-color: $spgreen;
								display: flex;
								justify-content: center;
								align-items: center;
								border-radius: 20px;
								margin-right: 10px;
							}
							.pin-container {
								width: 42px;
								height: 60px;
								background-color: $spmedium;
								display: flex;
								justify-content: center;
								align-items: center;
								border-radius: 20px;
	
								&.pinned {
									background-color: $spgreen;
								}
	
								img {
									width: 32px;
									height: 32px;
								}
							}
						}
					}
				}
			}
		}
	}
}

#order-summary {
	.col-md-6 {
		display: flex;
	}
	.container {

		#targetIframe {
			width: 100%;
			height: 975px;
			max-width: 600px;
			margin: 0 auto 50px;
			border: 0;
		}

		#page-title {
			margin-bottom: 10px;
		}

		h4.text-center {
			margin-bottom: 20px;
		}

		.supplier-box {
			border: 1px solid white;
			border-radius: 10px;
			padding: 1.5em;
			margin-bottom: 2em;

			h6 {
				font-size: 1.3em;
				color: $spwhite;
			}

			h5 {
				font-size: 1.2em;
			}

			.btn {
				margin-bottom: 0;
			}

			&.supplier-box--integrated {
				background: #002f36;

				h5 {
					color: white;
				}

				.supplier-box-instructions {
					border-color: white;

					img {
						width: 2em;
						margin-right: 0.5em;
						margin-bottom: 4px;
					}

					* {
						font-weight: bold;
					}
				}
			}

			&.supplier-box--non-integrated {
				background: white;

				h5 {
					color: black;
				}

				h6 {
					color: #002f36;
				}

				.supplier-box-instructions {
					border-color: $spgreen;

					img {
						display: block;
						width: 2.5em;
						margin: 0 auto;
						margin-bottom: 0px;
						margin-bottom: 1em;
					}

					* {
						color: $spred;
						font-weight: bold;
					}
				}

				.supplier-box-send {
					.btn {
						border-color: $spgreen !important;
					}
				}
			}

			.supplier-box-instructions {
				padding: 2em 0;
				margin: 1.5em 0;
				text-align: center;
				border-top: 1px dotted;
				border-bottom: 1px dotted;
				font-size: 1.2em;
			}

			.supplier-box-buttons {
				margin-top: 12px;
			}

			.supplier-box-download {
				text-align: right;
				
				a {
					display: inline-block;
					background: $spgreen;
					border-radius: 40px;

					img.supplier-download {
						height: 44px;
						width: auto;
					}
				}
			}
		}
	}
}