#footer {
	width: 100%;
	background-color: $spblack;
	height: 80px;
	overflow-y: hidden;
	position: fixed;
	z-index: 9;
	bottom: 0;
	left: 0;

	.container {
		max-width: 800px;
		padding: 0;

		.col-md-2 {
			flex: 0 0 20%;
			max-width: 20%;

			.btn {
				text-align: center;

				.icon-wrapper, .icon-text {
					padding: 0;
				}

				.icon-wrapper {
					img {
						height: 30px;
					}

					margin-bottom: 8px;
				}
			}
		}
	}
}