#root .icon-button-container {
	flex: 1;
    margin-bottom: 20px;
	position: relative;

	.btn.has-icon.hasLongDesc {
		width: 84% !important;
		border-top-right-radius: 0 !important;
		border-bottom-right-radius: 0 !important;

		.icon-text {
			margin-left: 15px;

			p {
				text-align: left;
			}
		}
	}

	.icon-button-longdesc-trigger {
		position: absolute;
		top: 0;
		right: 0;
		align-items: center;
		justify-content: center;
		padding: 0 15px;
		border-top-right-radius: 11px;
		border-bottom-right-radius: 11px;
		height: 100%;
		background-color: #4f4449;
		display: flex;
	}
}

#root .btn {
	width: 100%;
	background: none;
	text-align: center;
	border-radius: 20px;
	font-size: 16px;
	border: 0;
	color: $spwhite;
	font-weight: 600;

	&.btn-disabled {
		opacity: 0.5 !important;
		cursor: default !important;
	}

	&.btn-small {
		width: auto;
		font-size: 0.8rem;
		margin-bottom: 0;
		height: auto;
	}

	&.text-light {
		color: $spwhite !important;
	}

	&.text-dark {
		color: $spblack !important;
	}

	&.has-icon {
		height: auto;
		text-align: left;
		border-radius: 20px !important;
		padding: 0;

		&.small {
			&.blank {
				padding-top: 10px;
				padding-bottom: 10px;
				margin: 0;
			}
		}

		&.medium {
			text-align: center;

			&.blank {
				padding-top: 0.375rem;
				padding-bottom: 0.375rem;
			}
		}

		.col-md-4 {
			flex: 0 0 33.333333%;
			max-width: 33.333333%;
		}

		.col-md-8 {
			flex: 0 0 66.666667%;
			max-width: 66.666667%;
		}

		.icon-wrapper{
			width: auto;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 1em 0 0.5em;

			&.highlighted, &.blank {
				border-radius: 10px;
				width: 60px;
				max-width: 60px;
				text-align: center;
				padding: 0;

				img {
					height: 37px;
					width: auto;
				}
			}

			&.highlighted {
				height: 60px;
				border: 1px solid $spgreen;
				background-color: $spgreen;
			}

			&.blank {
				border: 0;
				height: 0;
			}
		}

		.icon-wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 1em 0 0.5em;
		}

		.icon-text {
			flex: 1;
			text-align: center;

			font-size: 18px;
			line-height: 1em;
			text-align: left;

			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 0;

			p {
				display: block;
				margin: 0;
				width: 100%;

				&.icon-subtext {
					margin-top: 5px;
					text-align: left;
				}
			}

			&.long-text {
				text-align: left !important;
				display: flex;
				justify-content: flex-start;
			}

			&.blank {
				height: 0;
				line-height: 0 !important;
				margin-top: 12px;
			}
		}

		.icon-subtext {
			font-size: 16px;
			line-height: 1em;
		}

		.icon-arrow {
			text-align: right;
			padding: 0;
			display: flex;
			align-items: center;
			justify-content: flex-end;

			&.blank {
				img {
					margin-top: 0;
				}
			}

			&.open {
				img {
					height: 8.4px;
					width: 15px;
				}
			}

			img {
				height: 32px;
				width: 32px;
			}
		}

		&.big {
			height: 100px;
			.icon-wrapper {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 18px;
				height: 96px;
			}

			.icon-text {
				align-items: center;
				font-size: 1.2em;
			}
		}
	}

	&.green {
		color: $spwhite;
		border: 2px solid $spgreen;

		.icon-wrapper {
			border-radius: 20px 0 0 20px;
			min-width: 90px;
		}

		&:focus, &:hover, &:active {

			&.text-light {
				color: $spwhite !important;
			}

			&.text-dark {
				color: $spblack !important;
			}

			&.text-green {
				color: $spgreen !important;
			}
		}
	}

	&.primary,
	&.light {
		color: $splight;
		background-color: $spgreen;
		border-color: $spgreen;

		&:hover {
			background-color: $spwhite !important;
			border-color: $spwhite !important;
			color: $splight !important;

			&.text-light {
				color: $spwhite !important;
			}

			&.text-dark {
				color: $spblack !important;
			}
		}
	}

	&.secondary,
	&.dark {
		background-color: $spdark;
		color: $spwhite;
		border-color: $spdark;

		&:hover {
			background-color: $spblack !important;
			border-color: $spgreen !important;

			&.text-light {
				color: $spwhite !important;
			}

			&.text-dark {
				color: $spblack !important;
			}

			&.text-green {
				color: $spgreen !important;
			}
		}
	}

	&.secondary {
		background-color: $spmedium;
		color: $spgreen;
	}

	&.blank {
		background-color: transparent;
		border-color: transparent;

		&.border-white {
			border-color: $spwhite;
		}

		&:focus, &:hover, &:active {
			background-color: transparent !important;
			border-color: transparent !important;

			&.border-white {
				border-color: $spwhite !important;
			}
		}

		&.small {
			.icon-text {
				p {
					text-align: left;
				}
			}
		}
	}

	.icon {
		&.placeholder {
			background-color: $spblack;
			display: block;
		}

		&.big {
			height: 32px;
			width: auto;
			max-width: initial;
		}

		&.medium {
			height: 40px;
		}

		&.checkout {
			height: 40px;
		}

		&.small {
			height: 40px;
		}

		&.smaller {
			height: 25px;
		}

	}
}

#root {
	#smart-links {
		.icon-button-container {
			.btn {
				&.has-icon {
					.icon-wrapper {
						padding-right: 10px;
					}
					.icon-text {
						margin: 0;
						p {
							text-align: left;
						}
						&.blank {
							height: auto;
							line-height: 1em !important;
						}
					}
				}
			} 
		}
	}
}