#login {
	min-height: 100vh;
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;

	> div {
		width: 100%;
	}

	.login-container {
		display: flex;
		flex: 1;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		max-width: 400px;

		@include mobile {
			flex-direction: column;
			align-items: center;
		}

		.form {
			min-width: 400px;
			width: 400px;

			@include mobile {
				margin-bottom: 50px;
			}

			form {
				input {
					height: 54px;
					margin-bottom: 26px;
				}

				button {
					height: 54px;
				}


				.form-group {
					position: relative;
					input {
						border: 1px solid $spwhite;
						text-align: center;
						border-radius: 40px;
						padding: 0 50px;
					}

					.pw-visibility {
						position: absolute;
						right: 10px;
						top: 12px;
						width: 30px;
						height: 30px;
						min-width: 50px;
					}
				}
			}

			#forgot {
				margin-top: 16px;
			}
		}

		.logos {
			width: 300px;
			margin-top: 40px;

			@include mobile {
				display: block;
			}

			.welcome-footer {
				.nsai {
					margin-bottom: 25px;

					img {
						width: calc(33.33% - 10px);
						margin-left: 15px;

						&:first-of-type {
							margin-left: 0;
						}
					}
				}

				.drink-aware {
					display: flex;
					justify-content: space-between;
					margin-bottom: 25px;

					img {
						max-width: 200px;

						&:last-of-type {
							max-width: 45px;
						}
					}
				}

				.links {
					margin-bottom: 20px;
					display: flex;
					justify-content: space-between;
					text-align: center;

					a {
						padding: 7px;
					}
				}
			}
		}

		#logo {
			max-width: 200px;
			display: block;
			margin: 50px auto 70px;
		}

		@include mobile {
			max-width: 100%;

			.form {
				min-width: 300px;
			}
		}
	}
}