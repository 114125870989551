#financial-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    h1 {
        padding-top: 27px;
        margin-bottom: 27px;
    }

    #payment-info {
        padding-right: 0;
        box-shadow: -10px 6px 8px 0px rgba(0, 0, 0, 0.2);
        margin-left: 20px;

        @media (max-width:1200px)  {
            width: 100%;
            max-width: 100%;
            padding: 20px;
            margin: 0;
            box-sizing: border-box;
            box-shadow: none;
        }
    }
    
    .fin {
        display: flex;
        flex: 1;

        @media (max-width:1200px)  {
            flex-direction: column;
        }
    }

    #financial-buttons {
        padding: 0;
        border-radius: 12px;
        overflow: hidden;

        @media (max-width:1200px)  {
            width: 100%;
            max-width: 100%;

        }

        .icon-button-container {
            margin: 0;

            .btn {
                border-radius: 0 !important;
                background-color: transparent;
                border: none;
                padding: 10px 0;
                border-bottom: 1px solid $spdarkgray;

                &:hover {
                    border-bottom: 1px solid $spdarkgray !important;
                }

                .icon-wrapper {
                    padding: 0;
                    background-color: $splightgrey;
                    border-radius: 17px;
                    border: 0;
                    height: 80px;
                    min-width: 80px;

                    img {
                        width: 32px;
                        height: 32px;
                    }
                }

                .icon-text {
                    font-size: 24px;
                    margin-left: 15px;
                    line-height: 26px;
                    padding: 0;
                    background: none;

                    p {
                        text-align: left;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 24px;
                        
                        &.icon-subtext {
                            font-size: 14px;
                            color: $spwhite;
                            line-height: 20px;
                            font-weight: 400;
                        }
                    }
                }


                .icon-arrow {
                    width: auto;
                }
            }
        }

        .col-md-12 {
            padding: 0;
        }
    }

    .section-block {
        @media (max-width:1200px)  {
            margin: 0 20px 40px;
        }
        &:last-of-type {
            padding-top: 30px;
            margin-bottom: 40px;
            margin-right: 20px;
        }

        .title {
            p {
                font-size: 14px;
                line-height: 21px;
                margin: 0 auto;
                max-width: 450px;
                text-align: center;
            }
        }
    }

    .block--financial-info {
        border: 0;
        .history-order-detail {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
            width: 100%;
            padding-left: 20px;

            @media (max-width:1200px)  { 
                padding: 0;
            }

			.order-details {
				width: 100%;
				display: flex;
				align-items: center;
				flex-direction: column;
				text-align: center;
				border: 1px solid $spdarkgray;
				border-radius: 20px;
				flex: 0 0 calc(33.333% - 10px);
				color: #fff;
				margin: 5px;
				box-sizing: border-box;
				min-height: 150px;

				h4 {
					color: white;
					font-size: 12px;
					font-weight: 600;
					margin-bottom: 0;
					display: block;
					width: 100%;
					border-bottom: 1px solid $spdarkgray;
					padding: 10px;
				}

				h5 {
					font-size: 16px;
					font-weight: 400;
					display: block;
					padding: 10px;
					margin-bottom: 0;
                    line-height: 24px;
				}

				p {
					font-size: 12px;
					display: block;
					padding: 10px;
					margin-bottom: 0;
				}
			}
        }

        .history-order-summary {
            text-align: left;
            margin-bottom: 60px;
            padding-left: 16px;

            @media (max-width:1200px)  { 
                padding: 0;
                width: 100%;
            }

            h4 {
                font-size: 24px;
                font-weight: 600;
                line-height: 32px;
                margin-bottom: 20px;
            }

            p {
                font-size: 16px;
                line-height: 24px;
                font-weight: 400;
            }

            #financial-info-excerpt {
                font-size: 12px;
                line-height: 16px;
            }
        }

        .header {
            border-bottom: 0;
        }
    }
}