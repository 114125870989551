#smart-links {
    .page-title {
        font-size: 26px;
        text-align: center;
        width: 600px;
        border-bottom: 1px solid white;
        margin: 0 auto 40px;
        max-width: 90%;
    }
    .smartlinks-container {
        .smartlink-item {
            display: inline-block;
            flex: 0 0 33.333333%;
            position: relative;
        
            @media only screen and (max-width: 1000px) {
                flex-basis: 50%;
            }
        
            @media only screen and (max-width: 600px) {
                flex-basis: 100%;
            }
        
            .smartlink {
                background-color: white;
                display: flex;
                height: 150px;
                margin: 14px;
                border-radius: 10px;
                overflow: hidden;
        
                @media only screen and (max-width: 480px) {
                    flex-direction: row;
                    height: auto;
                    margin: 10px 20px;
                }
        
                .icon {
                    flex: 0 0 140px;
                    background-color: $spgreen;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
        
                    &.icon--icon {
                        display: none;
                    }
        
                    @media only screen and (max-width: 480px) {
                        flex: 1;
                        height: auto;
                    }
                }
        
                .content {
                    padding: 10px;
                    flex: 1;

                    h3, p {
                        color: $splight;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 20px;
                        font-weight: 100;
                    }
                    h3 {
                        font-weight: 600;
        
                        @media only screen and (max-width: 480px) {
                            font-size: 16px;
                            line-height: 20px;
                        }
                    }
                    p {
                        margin-bottom: 0;
                        -webkit-line-clamp: 4;
                        overflow: hidden;
                        -webkit-box-orient: vertical;
                        text-overflow: ellipsis;
                        display: -webkit-box;

                        @media only screen and (max-width: 480px) {
                            font-size: 14px;
                            line-height: 16px;
                        }
                    }
        
                    @media only screen and (max-width: 480px) {
                        flex: 2;
                        min-height: 130px;
                    }
                }

                .controls {
                    display: flex;
                    flex-direction: column-reverse;
                    justify-content: space-evenly;
                    align-items: center;

                    .smartlink-more:hover {
                        img {
                            filter: brightness(1.4) !important;
                        }

                        & ~ img {
                            filter: none !important;
                        }
                    }
                }

                &:hover {
                    .controls {
                        img {
                            filter: brightness(1.4);
                        }

                        .smartlink-more img {
                            filter: none;
                        }
                    }
                }
            }
        }
    }
}