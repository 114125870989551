#product-list-table {
	padding-left: 0;
	padding-right: 0;
	margin-bottom: 80px;

	@media (max-width:1200px)  {
		padding: 0 20px;
	}
	#page-title {
		margin: -84px 0 0 0;
		padding: 25px 0;
		height: 84px;
		background: black;
	}

	.col-md-1 {
		flex: 0 0 8.333333%;
		max-width: 8.333333%;
	}

	.col-md-2 {
		flex: 0 0 16.666667%;
		max-width: 16.666667%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.col-md-3 {
		flex: 0 0 25%;
		max-width: 25%;
	}

	.col-md-5 {
		flex: 0 0 41.666667%;
		max-width: 41.666667%;
	}

	.col-md-9 {
		flex: 0 0 75%;
		max-width: 75%;
	}

	.sub-header {
		max-width: 1200px;
		margin: 0 auto 20px;
		display: flex;
		align-items: center;
		.week-commencing {
			font-size: 24px;
			line-height: 32px;
			font-weight: 600;
			flex: 1;
			margin-bottom: 0;
		}
	}


	&.order-list {
		.product-list-item {

			.product-list-item-history {
				display: flex;
				height: 80px;
				background-color: $spdark;
				align-items: center;
				justify-content: center;
				box-shadow: inset 0px 6px 4px rgba(0,0,0,0.2);

				.product-list-item-history-content {
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;

					.history-arrow {
						background-color: $spgreen;
						display: flex;
						justify-content: center;
						align-items: center;
						border-radius: 20px;
						width: 60px;
						height: 42px;
						margin: 0 20px;

						.history-item-arrow {
							height: 32px;
							width: 32px;
						}

						&.history-arrow--prev {
							.history-item-arrow {
								transform: rotate(180deg);
							}
						}
					}

					.history-qty {
						border: 2px solid white;
						width: 42px;
						height: 60px;
						display: flex;
						justify-content: center;
						align-items: center;
						border-radius: 20px;
					}
					
					.history-date {
						display: flex;
						justify-content: center;
						align-items: center;

						.history-item-calendar {
							height: 32px;
							width: 32px;
							margin-right: 10px;
						}
					}
	
				}


			}
		}
	}

	.table-header {
		width: 100%;

		.col-md-3 {
			.text-right {
				white-space: nowrap;
				text-align: right;
			}
		}
	}
	

	//#product-list {
		.flex-fill {
			width: auto;
		}
		.product-list-item {
			width: 100%;
			display: block;
			border-top: 1px solid $spdarkgray;
			max-width: 1200px;
			margin: 0 auto;

			&.active {
				background-color: $spdark;
			}

			.toggle {
				display: flex;
				justify-content: center;
				align-items: center;
				min-width: 36px;
				height: 36px;				
				background-color: $spdark;
				border-radius: 36px;
				.tick {
					min-width: 22px;
					height: 22px;
					border-radius: 22px;
				}
			}

			.row {
				max-width: 1200px;
				margin: 0 auto;

				.col-md-12 {
					padding: 0;
				}

				.col-md-0 {
					padding-left: 0;
					display: flex;
					justify-content: center;
					align-items: center;
					padding-right: 0;
					width: 82px;

					&.justify-right {
						justify-content: flex-start;
						padding-left: 10px;
						width: 42px;
					}
					a {
						img.pin, img.remove {
							height: 32px;
							min-width: 32px;
						}

						img.arrow {
							min-width: 32px;
							height: 32px;
							transition: 0.2s;
						}

						img.arrow-down {
							transform: rotate(90deg);
						}
					}
				}

				.product-list-item-value {
					a {
						img.pin, img.arrow, img.remove {
							height: 25px;
						}

						img.arrow-down {
							width: 25px;
						}
					}
				}

				.remove-container {
					border-radius: 32px;
					height: 32px;
					min-width: 32px;
					display: flex;
					justify-content: center;
					align-items: center;
				}

				.product-list-item-left {
					text-align: left;
					align-items: center;
					display: flex;
					width: auto;

					button {
						background: initial;
						border: initial;
						padding: initial;
					}

					.product-list-item-image {
						float: left;
						height: 60px;
						width: 60px;
						margin: 8px 30px 8px 0;
						border-radius: 20px;
						background-color: $spwhite;
						text-align: center;
						overflow: hidden;

						img {
							height: 60px;
						}
					}

					.product-list-item-name-container {
						display: flex;
						flex-direction: row;
						align-items: center;
						padding: 10px 0;

						.order-item-bullet {
							width: 0;
							min-height: 60px;
							background-color: $splightgrey;
							border-radius: 10px;
							margin-right: 10px;
						}

					}

					.product-list-item-name {
						display: flex;
						height: 100%;
						flex-direction: column;
						justify-content: center;
						align-items: flex-start;

						
						&.sortable {
							line-height: 30px;
						}

						p {
							margin: 0;
							font-size: 16px;
							font-weight: 600;
							line-height: 24px;

							&.label {
								font-size: 12px;
								line-height: 16px;
								font-weight: 400;
								margin: 4px 0 0;
							}
						}

						a {
							font-size: 16px;
							font-weight: 600;
							line-height: 24px;
						}

						.min-order-qty {
							font-size: 12px;
							line-height: 16px;
							font-weight: 400;
						}
					}
				}

				.product-list-item-value {
					color: white;
					border-radius: 20px;
					border: 2px solid white;
					height: 42px;
					width: 42px;
					display: block;
					text-align: center;
					line-height: 40px;
					margin: 0 auto;
					font-size: 16px;
					font-weight: 600;

					.sortable {
						display: flex;
						justify-content: center;
						align-items: center;
						.editable {
							margin-top: 0 !important;
						}
					}

					&.editable {
						background-color: $spgreen;
						color: $splight;
						border: 2px solid $spgreen;
					}

					.editable {
						background-color: $spgreen;
						color: $splight;
						border: 2px solid $spgreen;
						border-radius: 20px;
						height: 42px;
						width: 42px;
						display: block;
						text-align: center;
						line-height: 40px;
						margin: 0 auto;
						font-size: 16px;
						font-weight: 600;
					}

					.uneditable {
						color: $spwhite;
						border-radius: 20px;
						border: 2px solid white;
						height: 42px;
						width: 42px;
						display: block;
						text-align: center;
						line-height: 42px;
						margin: 0 auto;
					}
				}

				.pin-container {
					align-items: center;
					background-color: #026d7c;
					border-radius: 20px;
					display: flex;
					height: 42px;
					justify-content: center;
					width: 42px;
					border: none;

					&.pinned {
						background-color: #cefab7;
					}
				}

				.product-list-item-right {
					padding-right: 0;
					text-align: right;
					display: flex;
					justify-content: flex-end;
					align-items: center;

					.editable-content {
						display: flex;
						align-items: center;

						display: flex;
						gap: 0.5em;
					}

					.product-list-item-value {
						.editable {
							margin-right: 4px;
						}

						a img.star-icon {
							height: 30px;
							width: auto;
						}
					}
				}
			}
		}
	//}
}

#product-list-buttons-footer {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: $splight;
	box-shadow: 0px 10px 10px 20px rgba(0, 0, 0, 0.2);

	.container {
		display: flex;
		justify-content: center;
		
		.col-md-6 {
			display: flex;
			
			button {
				flex: 1;
				margin: 1em;
			}

			.blank-error {
				text-align: center;
				flex: 1;
				display: flex;
				align-items: center;
			}
		}
	}

}

#root .product-category {
	.icon-button-container {
		border-top: 1px solid #696d77;
		margin: 0;
		width: 100%;

		.btn.has-icon.medium.blank {
			background-color: transparent;
			border: none;
			border-radius: 0 !important;
			margin: 0;
			padding: 10px 0;

			.icon-wrapper  {
				background-color: white;
				border-color: white;
				margin-right: 10px;
				img {
					height: 32px;
				}
			}

			.icon-text {
				font-size: 16px;
				font-weight: 600;
				line-height: 24px;
			}
		}
	}
}
