#pub-info {
    margin-top: 30px;
    @media (max-width:1200px)  {
		padding: 0 20px;
	}
    .block--next-delivery {
        width: auto;
        margin: 0 0 30px 0;
        border: 0;
    }
}