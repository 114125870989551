#root  .search-container {
    display: flex;
    flex: 1;
    margin-bottom: 30px;
    align-items: center;

    @media (max-width:1200px)  {
        margin: 0 20px 10px;
    }

    @media (max-width:1000px)  {
        flex-direction: column;
    }

    button#date-search-btn {
        width: auto;
        border-radius: 0 12px 12px 0;
        background-color: $splight !important;
        border: none;
        color: #ffffff !important;
        padding: 0 20px;

        &:hover {
            background-color: $splight !important;
            color: #ffffff !important;
        }

        @media (max-width:1000px)  {
			border-radius: 14px;
            width: calc(100% - 28px);
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 6px 0;
		}

        img {
            width: 26px;
            margin-right: 10px;

            @media (max-width:1000px)  {
                width: 20px;
                margin-right: 10px;
                align-self: center;
                padding: 0 20px;
            }
        }
    }

    .btn {
        flex: 0;
        min-height: 44px;
        padding: 0 20px;

        @media (max-width:1000px)  {
            margin: 0 0 10px 0 !important;
        }
    }

    .search-bar {
        padding: 14px 0;
        border-radius: 12px 0 0 12px;
        display: flex;
        flex: 1;
        align-items: center;

        @media (max-width:1000px)  {
			flex-direction: column;
            width: 100%;
		}

        .input {
            height: 44px;
            @media (max-width:1000px)  {
                width: 100%;
                margin-bottom: 10px;
                margin-right: 0;
            }
        }

        #calendar-range {
            flex: 1;

            @media (max-width:1000px)  {
                margin-right: 0;
            }
        }

        #filters {
            border: 1px solid $spgreen;
            border-radius: 23px;
            flex: 2;

            @media (max-width:1000px)  {
                max-height: 46px;
                width: 100%;
                margin: 0;
            }

            .label {
                font-size: 14px;
                margin-right: 12px;
            }

            .btn {
                width: auto;
                font-size: 14px;
                flex: 1;
                border-radius: 0;
                border-right: 1px solid $spgreen;
                height: 42px;
                padding: 0 10px;

                &:first-of-type {
                    border-radius: 23px 0 0 23px;
                }
    
                &:last-of-type {
                    border-radius: 0 23px 23px 0;
                    border-right: 0;
                }

                &.secondary {
                    background-color: transparent;
                    color: white;
                }

                &:hover {
                    background-color: $spgreen !important;
                    color: $splight;
                }
            }
        }

        >div {
            display: flex;
            align-items: center;
            margin-right: 20px;

            @media (max-width:1000px)  {
                flex-wrap: wrap;

                .btn {
                    margin-bottom: 6px;
                }
            }

            &#calendar-range {
                height: 44px;
                @media (max-width:1000px)  {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .calendar-container#calendar-from {
            margin-top: 0;
            height: 44px;
        }

        .calendar-container {
            .react-datepicker-wrapper {
                margin-bottom: 0;
            }
            label {
                z-index: 0;
                margin-bottom: 0;
                background-size: 32px 32px;
            }
            input {
                border-color: white;
                margin-bottom: 0;
            }
        }
        .suppliers {
            border-color: white;
            margin-bottom: 0;
        }
        .ref {
            border-radius: 40px;
            border: 1px solid #fff;
            padding: 0 14px;
            margin-bottom: 0;
            &::placeholder {
                color: white;
                opacity: 1;
            }
            &:-ms-input-placeholder {
                color: white;
            }
            &::-ms-input-placeholder {
                color: white;
            }
        }
        .input {
            margin-right: 10px;

            @media (max-width:1000px)  {
                margin-right: 0;
            }
        }
    }
}