input, textarea, select {
    padding: 7px 0;
    background: transparent;
    border: none;
    border-bottom: 1px solid $spwhite;
    color: $spwhite;
    width: 100%;
    text-align: left;
    margin-bottom: 16px;
    height: 44px;
    font-size: 16px;

    .light {
        border-bottom: 1px solid $spblack;
        color: $spblack;
    }

    .dark {
        border-bottom: 1px solid $spwhite;
        color: $spwhite;
    }

    .blue {
        border-bottom: 1px solid $spgreen;
        color: $spwhite;
    }
}

input[type=date],
select {
    border: 1px solid #fff;
    border-radius: 20px;
    padding: 5px 15px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url('../../assets/icons/down-green.svg');
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: 32px auto, 100%;

    option {
        color: black;
    }
}

.react-datepicker-wrapper {
    display: block;
    margin-bottom: 15px;

    .react-datepicker__input-container {
        height: 40px;

        input {
            display: block;
            border-radius: 20px;
            border: 1px solid #fff;
            cursor: context-menu;
            padding: 0 15px;
        }
    }
}

.react-datepicker__tab-loop {
    .react-datepicker-popper {
        transform: none;
        z-index: 99;

        .react-datepicker {
        }
    }
}

input[type=date], label, a, select {
    outline: none !important;
    box-shadow: none !important;
}

input[type=date]::-webkit-calendar-picker-indicator {
    background: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    position: absolute;
    height: auto;
}

textarea {
    min-height: 100px;
    border: 1px solid #fff;
    border-radius: 20px;
    padding: 15px;

    &::placeholder {
        color: gray;
    }
}

.calendar-container {
    position: relative;

    a, label {
        position: absolute;
        top: 0px;
        left: 1em;
        right: 1em;
        background-color: transparent;
        z-index: -1;
        background-position: right center;
        background-repeat: no-repeat;
        background-size: 1.3em;
        line-height: 40px;
        height: 40px;

        &.calendar-label--hidden {
            background: transparent !important;
            color: transparent;
        }
    }
}