#home {
	position: relative;
	display: flex;
	align-items: center;

	h3 {
		text-align: center;
		margin: 0 0 40px;
	}

	
	.header {
		margin-bottom: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		#logo {
			max-width: 150px;
			display: block;
			margin: 60px auto 20px;

		}
		h2 {
			font-size: 24px;
			line-height: 26px;
			font-weight: 600;
		}
		.pub-switch {
			display: flex;
			margin-bottom: 20px;
			align-items: center;
	
			span {
				font-weight: 400;
				margin-right: 14px;
				line-height: 26px;
				font-size: 24px;
				text-transform: capitalize;
			}
	
			img {
				width: 32px;
				height: 32px;
			}
		}
	}

	.home-main-nav {
		max-width: 1200px;
		margin: 0 auto;
		.home-nav {
			.col-md-3, .col-md-2 {
				@include mobile-mini {
					max-width: 100%;
					flex: 0 0 100%;
				}
			}
		}
	}


	.home-footer-container {
		margin-top: 40px;
		box-shadow: 0px 10px 14px 20px rgba(0, 0, 0, 0.2);
		padding: 20px 0;
		
		.home-footer {
			margin: 30px;
			list-style-type: none;
			margin: 0;
			padding: 0;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			width: 100%;
			position: relative;
	
			li {
				padding: 0;
				min-width: 100px;
				max-width: 120px;
				
				button {
					text-align: center;
					margin: 0;
					cursor: pointer;
					font-size: 14px;
					padding: 8px;
					display: flex;
					flex-direction: column;
					align-items: center;
					font-weight: 500;
	
					img {
						max-height: 32px;
						margin-bottom: 14px;
					}
				}
			}
		}
	}

	#dates {
		margin-top: 15px;
		margin-bottom: 35px;
	}
}