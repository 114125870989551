#documents {
    #documents-container {
        border-radius: 12px;
        margin-bottom: 120px;
        padding: 0;

        @media (max-width:1200px)  { 
            padding: 0 20px;
        }

        #documents-buttons {
            background-color: $splight;
            box-shadow: 0px 10px 10px 20px rgba(0, 0, 0, 0.2);
            display: flex;
            justify-content: center;
            position: fixed;
            bottom: 0;
            width: 100%;
            left: 0;
            margin: 0;
            padding: 20px 0;

            #download-btn {
                display: flex;
            }

            button.btn {
                width: auto;
                margin-right: 8px;
                padding: 10px 17px;
                line-height: 26px;

                img {
                    height: auto;
                    margin-right: 12px;
                    height: 26px;
                    margin-top: -4px;
                }

                >div {
                    margin-right: 12px;
                }

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }

        #invoices {
            .invoice-item {
                border-top: 1px solid $spdarkgray;
                height: 100px;
                display: flex;

                .invoice-item-link {
                    flex: 1;
                    display: flex;
                    flex-direction: row;
                }

                .invoice-item-content {
                    display: flex;
                    flex: 1;

                    .invoice-item-arrow {
                        align-self: center;
                    }

                    .invoice-item-indicator-container {
                        align-self: center;
                        padding: 20px 20px 20px 0;

                        .invoice-item-indicator {
                            width: 36px;
                            height: 36px;
                            justify-content: center;
                            align-items: center;
                            background-color: $spdark;
                            border-radius: 100%;
                            display: flex;

                            &.loading {
                                background-color: transparent;
                                border-radius: 0px;
                            }

                            .invoice-item-indicator-inner {
                                width: 22px;
                                height: 22px;
                                background-color: $splight;
                                border-radius: 100%
                            }
                        }
                    }
                    
                    .invoice-item-label-container {
                        display: flex;
                        flex: 1;
                        align-items: center;

                        .invoice-item-label {
                            text-transform: uppercase;
                            margin: 0;
                            font-size: 22px;
                            line-height: 24px;
                            font-weight: normal;
                        }

                        .invoice-item-metadata {
                            display: flex;
                            font-size: 18px;
                            line-height: 20px;

                            span {
                                color: #1FA6A6;
                                margin-left: 20px;

                                &:first-of-type {
                                    margin-left: 34px;
                                }
                            }
                        }
                    }

                    .invoice-item-arrow img {
                        width: 32px;
                        height: 32px;
                    }
                }

                &.selected {
                    .invoice-item-content {
                        .invoice-item-indicator {
    
                            .invoice-item-indicator-inner {
                                background-color: $spgreen;
                            }
                        }
                    }
                }
            }
        }
    }
}