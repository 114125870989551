$spgreen: #CEFAB7;
$splight: #00414B;
$spmedium: #026D7C;
$spdark: #003037;
$spwhite: #ffffff;
$spblack: #000000;
$spgray: #CDCFD2;
$splightgrey: #F1F2F4;
$spdarkgray: #696D77;
$spgradientgreen: #0b3b3b;
$spred: #F74429;
$green: #1B9D54;
$spsmborder: 1.5px;
$spborder: 2px;

$mobile-mini-width: 935px;
$mobile-width: 1500px;
$desktop-width: 1501px;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400;600;700&display=swap');

//Mixins

@mixin gradient($dir: to top, $from: $spgradientgreen, $to: $spblack 55%) {
	background: linear-gradient($dir, $from, $to) no-repeat 100% 100%;
}

@mixin background-size($size) {
    background-position: center;
    background-repeat: no-repeat;
    background-size: $size;
}

@mixin background-overlay($opacity: 0.3) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 65, 75, $opacity);
}


@mixin aspect-ratio($width, $height) {
    position: relative;
    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: calc($height / $width) * 100%;
    }
    > .ratio-container {
        position: absolute;
        top: 0; 
        left: 0;
        right: 0;
        bottom: 0;
    }
}

@mixin mobile-mini {
    @media (max-width: #{$mobile-mini-width}) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: #{$mobile-width}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$mobile-width}) and (max-width: #{$desktop-width - 1px}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$desktop-width}) {
        @content;
    }
}


//Generic

::-webkit-input-placeholder {
	color: #ffffff;
}

:-ms-input-placeholder {
	color: #ffffff;
}

::placeholder {
	color: #ffffff;
}